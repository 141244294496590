import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { EVENT_PAGES } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { clearEventSlice } from '../../store/eventSlice';
import { clearModalsSlice } from '../../store/modalsSlice';
import EventView from '../events/EventView';
import EventForm from '../forms/EventForm';

export default function EventViewModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals } = useTypedSelector((state) => state);

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (modals.eventViewModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.eventViewModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());
  }

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={'5xl'}>
      <ModalOverlay />
      <ModalContent
        data-test-id="view-event-modal-content"
        p={0}
        borderRadius="1.375rem"
        overflow="hidden"
      >
        <ModalCloseButton
          top="1rem"
          right="1rem"
          zIndex={2}
          borderRadius="8rem"
          bgColor="#0000000A"
          data-test-id="create-event-modal-close-button"
        />
        {modals.eventModalPage === EVENT_PAGES.UPDATE ? (
          <EventForm closeModal={closeModal} />
        ) : (
          <EventView />
        )}
      </ModalContent>
    </Modal>
  );
}
