import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AUTH_MODAL_PAGES,
  EVENT_BOOK_PAGES,
  EVENT_PAGES,
  SUBSCRIBE_MODAL_PAGES,
} from '../constants/enums';

export interface Modals {
  authModalOpen: boolean;
  authModalPage: AUTH_MODAL_PAGES;
  subscribeModalOpen: boolean;
  subscribeModalPage: SUBSCRIBE_MODAL_PAGES;
  cookieModalOpen: boolean;
  eventBookModalOpen: boolean;
  bookModalPage: EVENT_BOOK_PAGES;
  eventModalOpen: boolean;
  eventModalPage: EVENT_PAGES;
  eventViewModalOpen: boolean;
  profileModalOpen: boolean;
  videoModalOpen: boolean;
  emailAttendeesModalOpen: boolean;
}

export const initialState: Modals = {
  authModalOpen: false,
  authModalPage: AUTH_MODAL_PAGES.SIGN_UP,
  subscribeModalOpen: false,
  subscribeModalPage: SUBSCRIBE_MODAL_PAGES.DETAILS,
  cookieModalOpen: false,
  eventBookModalOpen: false,
  bookModalPage: EVENT_BOOK_PAGES.DETAILS,
  eventModalOpen: false,
  eventViewModalOpen: false,
  profileModalOpen: false,
  videoModalOpen: false,
  emailAttendeesModalOpen: false,
  eventModalPage: EVENT_PAGES.CREATE,
};

const slice = createSlice({
  name: 'modals',
  initialState: initialState,
  reducers: {
    clearModalsSlice() {
      return initialState;
    },
    openAuthModal(state, action: PayloadAction<AUTH_MODAL_PAGES>) {
      if (action.payload) {
        // Set starting page for auth modal
        state.authModalPage = action.payload;
      }
      state.authModalOpen = true;
      state.subscribeModalOpen = false;
    },
    setAuthModalPage(state, action: PayloadAction<AUTH_MODAL_PAGES>) {
      state.authModalPage = action.payload;
    },
    openSubscribeModal(state, action: PayloadAction<SUBSCRIBE_MODAL_PAGES>) {
      if (action.payload) {
        // Set starting page for subscribe modal
        state.subscribeModalPage = action.payload;
      }
      state.subscribeModalOpen = true;
      state.authModalOpen = false;
    },
    setSubscribeModalPage(state, action: PayloadAction<SUBSCRIBE_MODAL_PAGES>) {
      state.subscribeModalPage = action.payload;
    },
    openCookieModal(state) {
      state.cookieModalOpen = true;
    },
    openEventBookModal(state, action: PayloadAction<EVENT_BOOK_PAGES>) {
      if (action?.payload) {
        state.bookModalPage = action.payload;
      }
      state.eventBookModalOpen = true;
    },
    setBookEventPage(state, action: PayloadAction<EVENT_BOOK_PAGES>) {
      state.bookModalPage = action.payload;
    },
    openEventModal(state, action: PayloadAction<EVENT_PAGES>) {
      if (action.payload) {
        state.eventModalPage = action.payload;
      }
      state.eventModalOpen = true;
    },
    openEventViewModal(state) {
      state.eventViewModalOpen = true;
    },
    setEventModalPage(state, action: PayloadAction<EVENT_PAGES>) {
      state.eventModalPage = action.payload;
    },
    openProfileModal(state) {
      state.profileModalOpen = true;
    },
    openVideoModal(state) {
      state.videoModalOpen = true;
    },
    openEmailAttendeesModal(state) {
      state.emailAttendeesModalOpen = true;
    },
  },
});

const { actions, reducer } = slice;
export const {
  clearModalsSlice,
  openAuthModal,
  setAuthModalPage,
  openSubscribeModal,
  setSubscribeModalPage,
  openCookieModal,
  openEventBookModal,
  openEventModal,
  openEventViewModal,
  openProfileModal,
  openVideoModal,
  openEmailAttendeesModal,
} = actions;
export default reducer;
