import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spinner,
  Textarea,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { EVENT_BOOK_PAGES } from '../../constants/enums';
import { useAppDispatch, useTypedSelector } from '../../hooks/store';
import { useEmailQueensInEventMutation } from '../../store/api';
import { clearEventSlice } from '../../store/eventSlice';
import { clearModalsSlice } from '../../store/modalsSlice';

const eventTypeLabel = {
  fontSize: '1rem',
  lineHeight: '1.25rem',
  fontWeight: 400,
  textAlign: { lg: 'center' },
} as const;

export default function EmailAttendeesModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modals, event } = useTypedSelector((state) => state);

  const [messageToQueens, setMessageToQueens] = useState<string>('');

  const [emailQueens] = useEmailQueensInEventMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const toast = useToast();

  const t = useTranslations('BossHub.emailAttendees');

  const dispatch: any = useAppDispatch();

  useEffect(() => {
    if (modals.emailAttendeesModalOpen && !isOpen) {
      onOpen();
    }
    if (!modals.emailAttendeesModalOpen && isOpen) {
      onClose();
    }
  });

  function closeModal() {
    // analyticsEvent(VIDEO_MODAL_CLOSED);
    setMessageToQueens('');
    dispatch(clearModalsSlice());
    dispatch(clearEventSlice());

    toast({
      title: 'Email sent',
      status: 'success',
      isClosable: true,
    });
  }

  function onClickEmailAttendees() {
    emailQueens({ eventId: event.id || '', message: messageToQueens });
    setLoading(true);
    setTimeout(() => {
      closeModal();
      setLoading(false);
    }, 1000);
  }

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size={isMobile ? 'full' : '4xl'}>
      <ModalOverlay />
      <ModalContent
        data-test-id="email-attendees-modal-content"
        px={{ base: '2rem', lg: '12rem' }}
        py={{ base: '12rem', lg: '14rem' }}
        borderRadius={{
          base: 0,
          lg: modals.bookModalPage === EVENT_BOOK_PAGES.DETAILS ? '1.375rem' : '0.75rem',
        }}
        maxH="full"
        overflow="hidden"
      >
        <ModalCloseButton
          top="1rem"
          right="1rem"
          zIndex={2}
          borderRadius="8rem"
          bgColor="#0000000F"
          textColor="black"
          data-test-id="book-event-modal-close-button"
        />
        {loading ? (
          <Flex
            direction="column"
            h="12.25rem"
            gap="2.265rem"
            alignItems="center"
            justifyContent="center"
            data-test-id="loading-spinner"
          >
            <Heading size="md">{t(`loading`)}</Heading>
            <Spinner
              thickness="6px"
              speed="0.65s"
              emptyColor="gray.100"
              color="pink.500"
              h="80px"
              w="80px"
            />
          </Flex>
        ) : (
          <Flex
            overflow="auto"
            w="100%"
            h="100%"
            alignItems="center"
            direction="column"
            gap="0.5rem"
          >
            <Heading size="md" mb={0}>
              {t('title')}
            </Heading>
            <FormControl data-test-id="message-to-queens-form-group">
              <FormLabel data-test-id="message-to-queens-label" sx={eventTypeLabel}>
                {t('messageToQueensLabel')}
              </FormLabel>
              <Textarea
                placeholder={t('messageToQueensPlaceholder')}
                value={messageToQueens}
                onChange={(e) => setMessageToQueens(e.target.value)}
              />
            </FormControl>
            <span style={{ alignSelf: 'flex-end' }}>
              <Tooltip
                hasArrow
                label={t('tooltipMessage')}
                isDisabled={messageToQueens !== ''}
                shouldWrapChildren
              >
                <Button
                  colorScheme="pink"
                  w="fit-content"
                  size="md"
                  onClick={onClickEmailAttendees}
                  disabled={messageToQueens === ''}
                >
                  {t('button')}
                </Button>
              </Tooltip>
            </span>
          </Flex>
        )}
      </ModalContent>
    </Modal>
  );
}
